<template>
  <g>
    <svg:style>
      .cls-2, .cls-5, .cls-6 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#glass-pattern);
      }

      .cls-5 {
        fill: url(#inox-gradient);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }
    </svg:style>

    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 59.77"
                    :y1="doorTopHeight1 + 184.51"
                    :x2="doorLeftWidth1 + 82.35"
                    :y2="doorTopHeight1 + 184.51"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="inox-gradient-2"
                    data-name="inox"
                    :x1="doorLeftWidth1 + 59.77"
                    :y1="doorTopHeight1 + 46.43"
                    :x2="doorLeftWidth1 + 82.35"
                    :y2="doorTopHeight1 + 46.43"
                    xlink:href="#handle-gradient"/>
    <g id="R02">
      <circle filter="url(#inset-shadow)"
              id="glass"
              class="cls-2"
              :cx="`${doorLeftWidth1 + 71.06}`"
              :cy="`${doorTopHeight1 + 79.84}`"
              r="23.84"/>
      <path id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-5"
            :d="`
              M ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 108.65}
              a 28.67 , 28.67 , 0 , 0 , 1 -11.29 -2.3
              V ${doorTopHeight1 + 262.68}
              H ${doorLeftWidth1 + 82.35}
              V ${doorTopHeight1 + 106.35}
              A 28.67 , 28.67 , 0 , 0 , 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 108.65}
              Z
              m 0 , 132.73
              A 5.37 , 5.37 , 0 , 1 , 1 , ${doorLeftWidth1 + 76.44} , ${doorTopHeight1 + 236}, 5.37 , 5.37 , 0 , 0, 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 241.38}
              Z
              m 0 -26.34
              a 5.37 , 5.37 , 0 , 1 , 1 , 5.37 -5.37
              A 5.37 , 5.37 , 0 , 0 , 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 215}
              Z
              m 0 -26.34
              a 5.37 , 5.37 , 0 , 1 , 1 , 5.37 -5.37
              A 5.37 , 5.37 , 0 , 0 , 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 188.71}
              Z
              m 0 -26.34
              A 5.37 , 5.37 , 0 , 1 , 1 , ${doorLeftWidth1 + 76.44} , ${doorTopHeight1 + 157} , 5.37 , 5.37 , 0 , 0 , 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 162.37}
              Z
              m 0 -26.34
              a 5.37 , 5.37 , 0 , 1 , 1 , 5.37 -5.37
              A 5.37 , 5.37 , 0 , 0 , 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 136}
              Z
            `"/>
      <path id="inox-2"
            v-if="showInox"
            data-name="inox"
            class="cls-6"
            :d="`
            M ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 51.11}
            a 28.67 , 28.67 , 0 , 0 , 1 , 11.29 , 2.3
            v -14
            H ${doorLeftWidth1 + 59.77}
            v 14
            A 28.67 , 28.67 , 0 , 0 , 1 , ${doorLeftWidth1 + 71.06} , ${doorTopHeight1 + 51.11}
            Z
            `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
